<style>.wrapper {
  display: flex;
  padding-top: 1rem;
  min-height: calc(100vh - var(--top-bar-offset));
}</style>

<script>
import { currentUser } from "@local/store/auth"
import ThreeLayout from "@shared/components/ThreeLayout.svelte"
import { Tabs, TabList, TabPanel, Tab } from "@local/components/_Tabs"
import Queue from "./queue.svelte"
import PastPatients from "./pastPatients.svelte"
import ScheduledPatients from "./scheduledPatients.svelte"
import ReferralPatients from "./referralPatients.svelte"
import { workflowLang } from "@shared/store/workflowKey"
import {
  liveQueueText,
  pastPatientsText,
  scheduledPatientsText,
  referralPatientsText,
  reviewPatientsText,
} from "@shared/utils/translations"
import ReviewPatients from "./reviewPatients.svelte"
</script>

<ThreeLayout layoutOnly="{true}">
  <div class="wrapper">
    <Tabs>
      <TabList>
        <Tab>{liveQueueText[$workflowLang]}</Tab>
        <Tab>{pastPatientsText[$workflowLang]}</Tab>
        <Tab>{scheduledPatientsText[$workflowLang]}</Tab>
        {#if $currentUser.roles.includes("assistant") || $currentUser.roles.includes("admin")}
          <Tab>{referralPatientsText[$workflowLang]}</Tab>
        {/if}
        {#if $currentUser.roles.includes("reviewer") || $currentUser.roles.includes("admin")}
          <Tab>{reviewPatientsText[$workflowLang]}</Tab>
        {/if}
      </TabList>
      <TabPanel className="border border-gray-400">
        <Queue />
      </TabPanel>
      <TabPanel className="border border-b-0 border-gray-400">
        <PastPatients />
      </TabPanel>
      <TabPanel className="border border-b-0 border-gray-400">
        <ScheduledPatients />
      </TabPanel>
      {#if $currentUser.roles.includes("assistant") || $currentUser.roles.includes("admin")}
        <TabPanel className="border border-b-0 border-gray-400">
          <ReferralPatients />
        </TabPanel>
      {/if}
      {#if $currentUser.roles.includes("reviewer") || $currentUser.roles.includes("admin")}
        <TabPanel className="border border-b-0 border-gray-400">
          <ReviewPatients />
        </TabPanel>
      {/if}
    </Tabs>
  </div>
</ThreeLayout>
