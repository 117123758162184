<style>.background {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  min-height: 100vh;
  --bg-opacity: 1;
  background-color: #000;
  background-color: rgba(0, 0, 0, var(--bg-opacity));
  --bg-opacity: 0.5;
}

.modal {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  border-radius: 0.125rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  max-width: 36rem;
  min-width: 50vw;
  max-height: 75vh;
  overflow: auto;
}</style>

<script>
import Button from "./Button.svelte"
import Render from "./Render.svelte"

export let elements = []
export let buttonLabel = "Show"
export let payload = undefined
export let actions = undefined
export let allErrors = undefined
export let className = ""
export let icon = undefined

let showModal = false
</script>

<div class="{className}">
  <Button
    type="button"
    color="primary"
    outlined="{false}"
    filled="{false}"
    label="{buttonLabel}"
    leftIcon="{icon}"
    iconSize="large"
    on:click="{() => (showModal = true)}"
  />
</div>

{#if showModal}
  <div class="background">
    <div class="modal">
      <Button
        className="button-sm hover:bg-blue-200 float-right"
        type="button"
        color="primary"
        outlined="{false}"
        filled="{false}"
        label=""
        leftIcon="close"
        on:click="{() => (showModal = false)}"
      />
      {#each elements as element}
        <Render
          actions="{actions}"
          element="{element}"
          allErrors="{allErrors}"
          bind:payload
        />
      {/each}
    </div>
  </div>
{/if}
