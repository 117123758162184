<style>div {
  border-width: 2px;
  border-radius: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

div.primary {
  background-color: var(--primary-lighter);
  color: var(--primary);
}

div.red {
  --bg-opacity: 1;
  background-color: #fed7d7;
  background-color: rgba(254, 215, 215, var(--bg-opacity));
  --border-opacity: 1;
  border-color: #fc8181;
  border-color: rgba(252, 129, 129, var(--border-opacity));
  color: "black";
}

div.blue {
  --border-opacity: 1;
  border-color: #63b3ed;
  border-color: rgba(99, 179, 237, var(--border-opacity));
  --bg-opacity: 1;
  background-color: #ebf8ff;
  background-color: rgba(235, 248, 255, var(--bg-opacity));
}

div.yellow {
  --border-opacity: 1;
  border-color: #f6e05e;
  border-color: rgba(246, 224, 94, var(--border-opacity));
  --bg-opacity: 1;
  background-color: #faf089;
  background-color: rgba(250, 240, 137, var(--bg-opacity));
}

h3 {
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
}

p {
  font-size: 1rem;
  font-weight: 500;
  margin-top: 1.5rem;
}</style>

<script>
export let title = ""
export let content = ""
export let className = ""
export let color = "primary"

$: primary = color === "primary"
$: red = color === "red"
$: blue = color === "blue"
$: yellow = color === "yellow"
</script>

<div
  class="{className}"
  class:primary="{primary}"
  class:red="{red}"
  class:blue="{blue}"
  class:yellow="{yellow}"
>
  {#if title}
    <h3>
      {title}
    </h3>
  {/if}
  {#if content}
    <p>{content}</p>
  {/if}
</div>
