<style>.label {
  font-size: 0.75rem;
  line-height: 1.67;
  letter-spacing: -0.29px;
}

.data {
  line-height: 1.75rem;
}</style>

<script>
import TemplateString from "@shared/components/TemplateString.svelte"

export let label = ""
export let data = undefined
export let className = ""
export let exposeLineBreaks = false
</script>

<div class="wrapper {className}">
  <div class="label">{label}</div>
  <div
    class="data type-paragraph"
    class:whitespace-pre-wrap="{exposeLineBreaks}"
  >
    {#if data}
      <TemplateString content="{data}" />
    {:else}--{/if}
  </div>
</div>
