<style>.main :global(h1) {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.875rem;
  font-weight: 700;
}

.main :global(h2) {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-weight: 600;
}

.main :global(p) {
  margin-bottom: 1rem;
}

.main :global(a) {
  text-decoration: underline;
  --text-opacity: 1;
  color: #63b3ed;
  color: rgba(99, 179, 237, var(--text-opacity));
}

.main :global(ol) {
  list-style-type: decimal;
  padding-left: 1.5rem;
}

.main :global(ul) {
  list-style-type: disc;
  padding-left: 1.5rem;
}

.main :global(li:not(:last-child)) {
  margin-bottom: 0.5rem;
}</style>

<script>
</script>

<div class="flex w-full max-w-screen-xl mx-auto py-8">
  <div class="main bg-white rounded-md flex-1 py-2 px-4">
    <slot />
  </div>
</div>
