<style>.wrapper {
  width: 100%;
  position: relative;
}

.input-wrapper {
  width: 100%;
  border-width: 0;
  border-bottom-width: 4px;
  border-radius: 0.25rem;
  position: relative;
  background-color: #f3f3f3;
  border-color: #757575;
}

.input-wrapper.disabled {
  background-color: #dcdcdc;
  border-color: #9b9b9b;
}

.input-wrapper.focused {
  border-color: var(--primary);
}

.input-wrapper.has-errors {
  border-color: #c4272a;
}

.input {
  width: 100%;
  background-color: transparent;
  border-width: 0;
  cursor: default;
  outline: 2px solid transparent;
  outline-offset: 2px;
  padding-top: 1.5rem;
  padding-bottom: 0.25rem;
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
  font-size: 1.125rem;
  padding-left: 1rem;
  padding-right: 2rem;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transition-duration: 100ms;
  appearance: none;
  line-height: 1.5rem;
}

.input:disabled {
  cursor: not-allowed;
  color: #757575;
}

.label {
  width: 100%;
  font-size: 1.125rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 2rem;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transition-duration: 100ms;
  position: absolute;
  top: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  color: #9b9b9b;
  line-height: 1.25rem;
}

.label.disabled {
  cursor: not-allowed;
  color: #757575;
}

.label.expanded {
  padding-bottom: 0.25rem;
  padding-top: 0.5rem;
  font-size: 0.75rem;
  line-height: 1;
}

.label.focused {
  color: var(--primary);
}

.options {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  padding-top: 0.5rem;
  overflow-y: scroll;
  position: absolute;
  z-index: 10;
  width: 100%;
  max-height: 27.5rem;
}

.option {
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  cursor: pointer;
}

.option.hover {
  background-color: #f3f3f3;
}

.caret {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  height: 3.25rem;
  width: 2rem;
  font-size: 1.125rem;
  color: #757575;
}

.caret.focused {
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
  --transform-rotate: 180deg;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  color: var(--primary);
}</style>

<script>
import { createEventDispatcher } from "svelte"

export let name = ""
export let label = ""
export let value = undefined
export let hasErrors = false
export let options = []
export let disabled = false
export let includeEmpty = false
export let className = ""
export let icon = undefined
export let iconClickable = undefined

const dispatch = createEventDispatcher()

let inputEl

let focused = false
$: expanded = value || focused
$: error = !focused && hasErrors
$: hasIcon = !!icon

let hovering = 0

function iconClicked() {
  if (iconClickable) dispatch("iconClick")
}

function focus() {
  focused = true
}

function blur() {
  focused = false
}
$: console.log()
</script>

<div class="wrapper {className}">
  <div class="flex items-center">
    <div
      class="input-wrapper"
      class:focused="{focused}"
      class:has-errors="{hasErrors}"
      class:disabled="{disabled}"
    >
      {#if label}
        <label
          for="{name}"
          class="label"
          class:focused="{focused}"
          class:expanded="{expanded}"
          class:disabled="{disabled}"
        >
          {label}
        </label>
      {/if}
      <select
        bind:this="{inputEl}"
        class="input"
        id="{name}"
        on:focus="{focus}"
        on:blur="{blur}"
        name="{name}"
        bind:value="{value}"
        disabled="{disabled}"
      >
        {#if includeEmpty}
          <option value></option>
        {/if}
        {#each options as option, i}
          <option value="{option.value || option}">
            {option.label || option}
          </option>
        {/each}
      </select>
      <i
        class="caret material-icons"
        class:focused="{focused}"
        class:disabled="{disabled}"
      >
        arrow_drop_down
      </i>
    </div>
    {#if hasIcon}
      <i
        class="mx-2 icon material-icons"
        class:clickable="{iconClickable}"
        on:click="{iconClicked}"
      >
        {icon}
      </i>
    {/if}
  </div>
</div>
