<style>.table {
  box-shadow: 0 0 3.5rem 0 rgba(0, 0, 0, 0.1);
}

.claim-icon {
  color: var(--primary);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  margin-left: auto;
  margin-bottom: 10px;
}

.phone-icon {
  color: #d26627;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  margin-left: auto;
}

.row-gray {
  --bg-opacity: 1;
  background-color: #f7fafc;
  background-color: rgba(247, 250, 252, var(--bg-opacity));
}

.row-red {
  --bg-opacity: 1;
  background-color: #fff5f5;
  background-color: rgba(255, 245, 245, var(--bg-opacity));
}

@media (max-width: 767px) {
  .table,
  .table tbody,
  .table th,
  .table td,
  .table tr {
    display: block;
  }
}</style>

<script>
import { onMount, onDestroy } from "svelte"
import { navigate } from "svelte-routing"
import socketio from "socket.io-client"

import { apiBasePath } from "@local/config/constants"
import {
  patchDoctorOrEducatorWorkflow,
  patchLanguage,
} from "@local/config/datasource"
import { getAccessToken, currentUser, auth } from "@local/store/auth"
import { workflowLang } from "@shared/store/workflowKey"
import {
  continueText,
  awaitingPatientsText,
  startText,
  callText,
  connectedPatientsText,
  youHaveNoConnectedPatientsText,
  youHaveNoPatientsWaitingText,
} from "@shared/utils/translations"

import TemplateString from "@shared/components/TemplateString.svelte"

let socket

let awaitingRows = []
$: awaitingHeads =
  awaitingRows.length > 0 ? awaitingRows[0].columns.map((row) => row.label) : []

let connectedRows = []
$: connectedHeads =
  connectedRows.length > 0
    ? connectedRows[0].columns.map((row) => row.label)
    : []

let observers = {}

async function connectWs() {
  if (socket) {
    socket.disconnect()
  }

  const language = $workflowLang || "en"

  const awaitingId = $currentUser.roles.includes("admin")
    ? "*"
    : $currentUser.id

  // observers.awaiting = `group:5f64f3e26d6bd91b551093a4`
  observers.awaiting = `group:${awaitingId}:5f64f3e26d6bd91b551093a4`
  observers.connected = `personal:${$currentUser.id}:5ff62418c3c3724229037c78`
  observers.connectedAll = `personal:*:5ff62418c3c3724229037c78`

  const jwt = await getAccessToken()
  socket = socketio(apiBasePath, {
    query: { jwt, language },
    transports: ["websocket"],
  })

  socket.on("connect", () => {
    socket.emit("observe", observers.awaiting)
    socket.emit("observe", observers.connected)
    socket.emit("observe", observers.connectedAll)
  })

  socket.on("error", (err) => {
    console.log(err)
  })

  socket.on("initialState", ({ path, data }) => {
    switch (path) {
      case observers.awaiting:
        awaitingRows = data
        break
      case observers.connected:
        connectedRows = data
        break
    }
  })

  socket.on("set", ({ path, data, ...rest }) => {
    let currIdx

    switch (path) {
      case observers.awaiting:
        currIdx = awaitingRows.findIndex((row) => row.wfId === data.wfId)
        if (currIdx >= 0) {
          awaitingRows[currIdx] = data
        } else {
          awaitingRows.push(data)
        }
        awaitingRows = awaitingRows
        break
      case observers.connected:
        currIdx = connectedRows.findIndex((row) => row.wfId === data.wfId)
        if (currIdx >= 0) {
          connectedRows[currIdx] = data
        } else {
          connectedRows.push(data)
        }
        connectedRows = connectedRows
        break
    }
  })

  socket.on("unset", ({ path, data, ...rest }) => {
    console.log("unset", { path, data, rest })
    switch (path) {
      case observers.awaiting:
        awaitingRows = awaitingRows.filter((row) => row.wfId !== data.wfId)
        break
      case observers.connectedAll:
        connectedRows = connectedRows.filter((row) => row.wfId !== data.wfId)
        break
    }
  })
}

$: {
  console.info(`Switching language to ${$workflowLang}`)
  connectWs()
  updateUserLanguage($workflowLang)
}

async function updateUserLanguage(language) {
  const partnerId = $currentUser.partnerId
  const userId = $currentUser.id
  const userLanguage = $currentUser.language

  if (userLanguage !== language) {
    try {
      const tokens = await patchLanguage({ partnerId, userId, language })
      auth(tokens)
    } catch (err) {
      console.error("Unable to save language preferences", err)
    }
  }
}

onDestroy(function () {
  if (socket) {
    socket.disconnect()
  }
})

const actions = {
  open: async function ({ wfId }) {
    navigate(`/doctor-workstation/workflows/${wfId}`)
  },
  claim: async function ({ wfId }) {
    await patchDoctorOrEducatorWorkflow(wfId, $currentUser.roles)
    navigate(`/doctor-workstation/workflows/${wfId}`)
  },
}

function applyStyle(row) {
  let returningType = row.classProps?.returningType
  if (returningType === "renewal") return "row-gray"
  if (returningType === "followUp") return "row-red"
  return ""
}

onMount(connectWs)
</script>

<div class="flex flex-col items-center justify-center flex-1">
  <div class="flex flex-col flex-1 w-full px-8 py-12 bg-white flex-grow-1">
    {#if awaitingRows.length > 0}
      <h1 class="mb-8 text-2xl font-semibold">
        {awaitingPatientsText[$workflowLang]}&nbsp;({awaitingRows.length}):
      </h1>

      <table class="table border border-collapse rounded">
        <thead class="max-md:hidden">
          {#each awaitingHeads as head}
            <th class="px-4 py-3 text-xl font-medium text-left border-t">
              {head}
            </th>
          {/each}
          <th class="px-4 py-3 text-xl font-medium text-left border-t">
            &nbsp;
          </th>
        </thead>
        <tbody>
          {#each awaitingRows as row}
            <tr class="border-t {applyStyle(row)}">
              {#each row.columns as { value }}
                <td class="px-4 py-3 text-xl">
                  <TemplateString content="{value}" />
                </td>
              {/each}
              <td class="px-4 py-3 text-xl align-middle">
                {#each row.buttons as button}
                  {#if button.action === "claim"}
                    <span
                      class="icon claim-icon hover:opacity-75"
                      on:click="{() => actions[button.action](row)}"
                    >
                      {startText[$workflowLang]}
                      <i class="material-icons"> arrow_forward </i>
                    </span>
                  {/if}
                  {#if button.action === "call"}
                    <span class="icon phone-icon hover:opacity-75">
                      <a href="{button.props?.url || ''}" target="_blank">
                        {callText[$workflowLang]}
                        <i class="material-icons"> phone </i>
                      </a>
                    </span>
                  {/if}
                {/each}
              </td>
            </tr>
          {/each}
        </tbody>
      </table>
    {:else}
      <h1 class="mb-8 text-2xl font-semibold">
        {youHaveNoPatientsWaitingText[$workflowLang]}
      </h1>
    {/if}
    {#if connectedRows.length > 0}
      <h1 class="mt-12 mb-8 text-2xl font-semibold">
        {connectedPatientsText[$workflowLang]}&nbsp;({connectedRows.length}):
      </h1>

      <table class="table border border-collapse rounded">
        <thead class="max-md:hidden">
          {#each connectedHeads as head}
            <th class="px-4 py-3 text-xl font-medium text-left border-t">
              {head}
            </th>
          {/each}
          <th class="px-4 py-3 text-xl font-medium text-left border-t">
            &nbsp;
          </th>
        </thead>
        <tbody>
          {#each connectedRows as row}
            <tr class="border-t {applyStyle(row)}">
              {#each row.columns as { value }}
                <td class="px-4 py-3 text-xl">
                  <TemplateString content="{value}" />
                </td>
              {/each}
              <td class="px-4 py-3 text-xl align-middle">
                {#each row.buttons as { action }}
                  <span
                    class="icon claim-icon hover:opacity-75"
                    on:click="{() => actions[action](row)}"
                  >
                    {continueText[$workflowLang]}
                    <i class="material-icons"> arrow_forward </i>
                  </span>
                {/each}
              </td>
            </tr>
          {/each}
        </tbody>
      </table>
    {:else}
      <h1 class="mt-12 mb-8 text-2xl font-semibold">
        {youHaveNoConnectedPatientsText[$workflowLang]}
      </h1>
    {/if}
  </div>
</div>
