<style>.button {
  @apply border-2;
  padding: 1rem;
  font-weight: 500;
  border-radius: 0.375rem;
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.button-sm {
  width: 3rem;
  @apply border-2;
  padding: 0.5rem;
  font-weight: 600;
  font-size: 1.125rem;
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.button:hover {
  opacity: 0.75;
}

.button.empty {
  border-color: white;
  background-color: white;
}

.button.empty.transparent,
.button.empty.primary {
  color: var(--primary);
}

.button.empty.black {
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
}

.button.filled {
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
}

.button.filled.black {
  --bg-opacity: 1;
  background-color: #000;
  background-color: rgba(0, 0, 0, var(--bg-opacity));
  --border-opacity: 1;
  border-color: #000;
  border-color: rgba(0, 0, 0, var(--border-opacity));
}

.button.filled.white {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  --border-opacity: 1;
  border-color: #fff;
  border-color: rgba(255, 255, 255, var(--border-opacity));
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
}

.button.filled.primary {
  background-color: var(--primary);
  border-color: var(--primary);
  color: var(--primary-accent);
}

.button.filled.transparent {
  background-color: "transparent";
  border-color: "transparent";
  color: var(--primary);
}

.button.outlined.black {
  --border-opacity: 1;
  border-color: #000;
  border-color: rgba(0, 0, 0, var(--border-opacity));
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
}

.button.outlined.white {
  --border-opacity: 1;
  border-color: #fff;
  border-color: rgba(255, 255, 255, var(--border-opacity));
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
}

.button.outlined.primary {
  color: var(--primary);
  border-color: var(--primary);
}

.button.outlined.transparent {
  color: var(--primary);
  border-color: "transparent";
}

.button.filled.primary:disabled,
.button.filled.black:disabled,
.button.filled.white:disabled,
.button.outline.primary:disabled,
.button.outline.black:disabled,
.button.outline.white:disabled {
  color: #757575;
  background-color: #dcdcdc;
  border-color: #dcdcdc;
}

.button.outline.transparent:disabled {
  color: #757575;
  background-color: "transparent";
  border-color: "transparent";
}

.label {
  display: flex;
  flex: 1 1 0%;
  align-items: center;
  justify-content: center;
}

.font-size-sm {
  font-size: 0.875rem;
}

.font-size-normal {
  font-size: 1rem;
}

.font-size-lg {
  font-size: 1.125rem;
}

.font-size-xl {
  font-size: 1.25rem;
}

.font-size-2xl {
  font-size: 1.5rem;
}

.font-size-3xl {
  font-size: 1.875rem;
}

.label.rightIcon {
  margin-right: -1.5rem;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}

.icon.rightIcon {
  flex-grow: 0;
}

.icon.leftIcon {
  margin-right: 0.5rem;
}

.icon.medium {
  font-size: 24px;
}

.icon.large {
  font-size: 36px;
}

.icon.extraLarge {
  font-size: 48px;
}

.hint {
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 1rem;
  font-size: 15px;
  letter-spacing: -0.36px;
  line-height: 1.4;
}

.button.wFull {
  width: 100%;
}

.button.wHalf {
  width: 50%;
}

.button.wThird {
  width: 33.333333%;
}

.button.wQuarter {
  width: 25%;
}

.button.wOneFifth {
  width: 20%;
}

.positionCenter {
  display: flex;
  justify-content: center;
}

.positionLeft {
  display: flex;
  justify-content: flex-start;
}

.positionRight {
  display: flex;
  justify-content: flex-end;
}

.border-none {
  @apply border-none;
}

.border-1 {
  @apply border-1;
}

.border-2 {
  @apply border-2;
}</style>

<script>
export let type = undefined
export let label = ""
export let filled = true
export let outlined = false
export let color = "black"
export let leftIcon = undefined
export let rightIcon = undefined
export let hint = undefined
export let disabled = false
export let className = ""
export let iconSize = "medium"
export let classNameLabel = ""
export let width = "full"
export let position = "center"

$: empty = !filled && !outlined

$: black = color === "black"
$: primary = color === "primary"
$: white = color === "white"
$: transparent = color === "transparent"
$: wFull = width === "full"
$: wHalf = width === "half"
$: wThird = width === "third"
$: wQuarter = width === "quarter"
$: wOneFifth = width === "oneFifth"
$: positionCenter = position === "center"
$: positionLeft = position === "left"
$: positionRight = position === "right"
</script>

<div
  class:positionCenter="{positionCenter}"
  class:positionLeft="{positionLeft}"
  class:positionRight="{positionRight}"
>
  <button
    type="{type}"
    on:click
    disabled="{disabled}"
    class="button border-2 {className}"
    class:filled="{filled}"
    class:outlined="{outlined}"
    class:black="{black}"
    class:primary="{primary}"
    class:transparent="{transparent}"
    class:white="{white}"
    class:leftIcon="{leftIcon}"
    class:rightIcon="{rightIcon}"
    class:empty="{empty}"
    class:wFull="{wFull}"
    class:wHalf="{wHalf}"
    class:wThird="{wThird}"
    class:wQuarter="{wQuarter}"
    class:wOneFifth="{wOneFifth}"
  >
    <span class="label {classNameLabel}" class:rightIcon="{rightIcon}">
      {#if leftIcon}
        <i class="icon leftIcon material-icons {iconSize}">{leftIcon}</i>
      {/if}
      {label}
    </span>
    {#if rightIcon}
      <i class="icon rightIcon material-icons {iconSize}">{rightIcon}</i>
    {/if}
  </button>
  {#if hint}
    <div class="hint">{hint}</div>
  {/if}
</div>
