<style>.wrapper {
  display: flex;
  flex: 1 1 0%;
  flex-direction: row;
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

.left-column {
  display: flex;
  flex-direction: column;
  width: 33.333333%;
  max-height: 100vh;
  position: sticky;
  top: 0;
  border-width: 1px;
  border-top-width: 0;
  border-bottom-width: 0;
  --border-opacity: 1;
  border-color: #cbd5e0;
  border-color: rgba(203, 213, 224, var(--border-opacity));
  min-height: calc(100vh - var(--top-bar-offset));
}

.right-column {
  display: flex;
  flex-direction: column;
  width: 66.666667%;
  border-width: 1px;
  border-top-width: 0;
  border-left-width: 0;
  --border-opacity: 1;
  border-color: #cbd5e0;
  border-color: rgba(203, 213, 224, var(--border-opacity));
  min-height: calc(100vh - var(--top-bar-offset));
}</style>

<div class="wrapper">
  <div class="left-column">
    <slot name="left" />
  </div>
  <div class="right-column">
    <slot name="right" />
  </div>
</div>
