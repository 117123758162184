<style>.wrapper {
  width: 100%;
  position: relative;
}

.input-wrapper {
  width: 100%;
  border-width: 0;
  border-bottom-width: 4px;
  border-radius: 0.25rem;
  position: relative;
  background-color: #f3f3f3;
  border-color: #757575;
}

.input-wrapper.disabled {
  background-color: #dcdcdc;
  border-color: #9b9b9b;
}

.input-wrapper.focused {
  border-color: var(--primary);
}

.input-wrapper.has-errors {
  border-color: #c4272a;
}

.input {
  width: 100%;
  background-color: transparent;
  border-width: 0;
  outline: 2px solid transparent;
  outline-offset: 2px;
  padding-top: 1.5rem;
  padding-bottom: 0.25rem;
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
  font-size: 1.125rem;
  padding-left: 1rem;
  padding-right: 1rem;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transition-duration: 200ms;
  appearance: none;
  line-height: 1.5rem;
}

.input::-webkit-calendar-picker-indicator {
  opacity: 0;
}

.label {
  width: 100%;
  font-size: 1.125rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 2rem;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transition-duration: 100ms;
  position: absolute;
  top: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  color: #9b9b9b;
  line-height: 1.25rem;
}

.label.disabled {
  color: #757575;
}

.label.expanded {
  padding-bottom: 0.25rem;
  padding-top: 0.5rem;
  font-size: 0.75rem;
  line-height: 1;
}

.label.focused {
  pointer-events: none;
  color: var(--primary);
}

.datalist {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  padding-top: 0.5rem;
  position: absolute;
  background-color: white;
  border: 1px solid blue;
  border-radius: 0 0 5px 5px;
  border-top: none;
  font-family: sans-serif;
  width: 465px;
  padding: 5px;
  max-height: 10rem;
  overflow-y: auto;
  max-height: 27.5rem;
}

option {
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  cursor: pointer;
}

option:hover {
  background-color: #f3f3f3;
}

.caret,
.loop {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  height: 3.25rem;
  width: 2rem;
  font-size: 1.125rem;
  color: #757575;
}

.caret.focused {
  --transform-translate-x: 0;
  --transform-translate-y: 0;
  --transform-rotate: 0;
  --transform-skew-x: 0;
  --transform-skew-y: 0;
  --transform-scale-x: 1;
  --transform-scale-y: 1;
  transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
  --transform-rotate: 180deg;
  transition-duration: 300ms;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  color: var(--primary);
}

.clickable {
  cursor: pointer;
}

.loop {
  right: 25px;
}

.animation-loop {
  -webkit-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }

  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}</style>

<script>
import { createEventDispatcher } from "svelte"
import { getMedicines } from "@local/config/datasource"

export let name = ""
export let label = "Medicine"
export let icon = undefined
export let iconClickable = undefined

export let options = []
export let disabled = false
export let value = undefined
export let className = ""

const dispatch = createEventDispatcher()

let inputEl

let focused = false
$: expanded = value || focused
$: hasIcon = !!icon

function iconClicked() {
  if (iconClickable) dispatch("iconClick")
}

function focus() {
  focused = true
}

function blur() {
  focused = false
}

async function loadMedications() {
  try {
    return await getMedicines()
  } catch (e) {
    console.log(e)
  }
}

options = loadMedications().then((medicines) => {
  options = medicines
})
</script>

<div class="wrapper {className}">
  <div class="flex items-center">
    <div class="input-wrapper" class:focused>
      <label
        for="{name}"
        class="label"
        class:focused
        class:expanded
        class:disabled
      >{label}</label>
      <input
        bind:this="{inputEl}"
        type="text"
        name="{name}"
        list="{name}-list"
        class="input"
        on:focus="{focus}"
        on:blur="{blur}"
        bind:value
        disabled="{disabled}"
      />
      <datalist class="datalist" id="{name}-list">
        {#if options && options.length > 0}
          {#each options as option, i}
            <option value="{option.value || option}">
              {option.label || option}
            </option>
          {/each}
        {/if}
      </datalist>
      {#if !options}
        <i
          class="loop material-icons animation-loop"
          class:focused
          class:disabled
        >
          loop
        </i>
      {/if}
      <i class="caret material-icons" class:focused class:disabled>
        arrow_drop_down
      </i>
    </div>
    {#if hasIcon}
      <i
        class="mx-2 icon material-icons"
        class:clickable="{iconClickable}"
        on:click="{iconClicked}"
      >
        {icon}
      </i>
    {/if}
  </div>
</div>
