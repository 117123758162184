<style>a {
  font-weight: 600;
  font-size: 1.125rem;
  color: var(--primary);
}

.font-size-sm {
  font-size: 0.875rem;
}

.font-size-normal {
  font-size: 1rem;
}

.font-size-lg {
  font-size: 1.125rem;
}

.font-size-xl {
  font-size: 1.25rem;
}

.font-size-2xl {
  font-size: 1.5rem;
}

.font-size-3xl {
  font-size: 1.875rem;
}</style>

<script>
import { link } from "svelte-routing"

export let href = ""
export { className as class }

let className = ""
</script>

<a href="{href}" class="{className}" use:link><slot /></a>
