<style>.font-size-sm {
  font-size: 0.875rem;
}

.font-size-normal {
  font-size: 1rem;
}

.font-size-lg {
  font-size: 1.125rem;
}

.font-size-xl {
  font-size: 1.25rem;
}

.font-size-2xl {
  font-size: 1.5rem;
}

.font-size-3xl {
  font-size: 1.875rem;
}

.font-weight-normal {
  font-weight: 400;
}

.font-weight-semibold {
  font-weight: 600;
}

.font-weight-bold {
  font-weight: 700;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}</style>

<script>
export let content = ""
export let className = ""
</script>

<p class="mb-4 whitespace-pre-wrap type-paragraph {className}">{content}</p>
