<style>.wrapper {
  width: 100%;
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

dl {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

dt {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
}

dd {
  display: flex;
  flex-direction: row;
  align-self: flex-start;
}

img {
  max-width: 80%;
  max-height: 80%;
}</style>

<script>
export let img
export let coupon = {}
export let productName = "Medical Cannabis Certification"

$: discountValue = coupon.value || 0
$: discountDollars = `-$${Math.floor(discountValue)}`
$: discountCents = Math.floor((discountValue % 1) * 100)
  .toString()
  .padStart(2, "0")

export let amount = 0
$: dollars = `$${Math.floor(amount)}`
$: cents = Math.floor((amount % 1) * 100)
  .toString()
  .padStart(2, "0")

$: total = amount - discountValue
$: totalDollars = `$${Math.floor(total)}`
$: totalCents = Math.floor((total % 1) * 100)
  .toString()
  .padStart(2, "0")
</script>

<div class="wrapper">
  <div class="flex justify-center items-center">
    <img src="{img}" alt="certification" />
  </div>
  <div>
    <h3 class="mb-6 text-center font-semibold text-lg">Order Summary</h3>
    <p class="mb-6">{productName}</p>
    <dl class="mb-6">
      <dt>Quantity:</dt>
      <dd>1</dd>
    </dl>
    <dl class="mb-6">
      <dt>Subtotal:</dt>
      <dd>{dollars}.{cents}</dd>
    </dl>
    {#if discountValue > 0}
      <dl class:discounted="{discountValue > 0}">
        <dt>Coupon {coupon.couponCode}:</dt>
        <dd>{discountDollars}.{discountCents}</dd>
      </dl>
    {/if}
    <dl class="mb-6">
      <dt>Sales tax:</dt>
      <dd>-$0</dd>
    </dl>
    <dl class="font-semibold text-2xl">
      <dt>Total:</dt>
      <dd>{totalDollars}.{totalCents}</dd>
    </dl>
  </div>
</div>
