<style>.wrapper {
  width: 100%;
}

.error {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  line-height: 1;
  color: #c4272a;
}</style>

<script>
import TextArea from "./_TextArea.svelte"
import Button from "./Button.svelte"

export let name = ""
export let label = ""
export let value = undefined
export let growable = false
export let resizable = false
export let template = undefined
export let rows
export let className = ""
export let buttonLabel = "USE TEMPLATE"
export let buttonColor = "primary"
export let buttonWidth = "quarter"
export let buttonPosition = "right"
export let disabled = false
export { _errors as errors }

let _errors

$: errors = _errors || []
$: hasErrors = errors.length > 0

function click() {
  value = template
}
</script>

<div class="wrapper {className}">
  <TextArea
    name="{name}"
    label="{label}"
    hasErrors="{hasErrors}"
    growable="{growable}"
    resizable="{resizable}"
    rows="{rows}"
    bind:value="{value}"
    disabled="{disabled}"
  />
  {#if hasErrors}
    <div class="error">{errors[0]}</div>
  {/if}
  {#if template && !disabled}
    <div class="mt-2">
      <Button
        on:click="{click}"
        label="{buttonLabel}"
        type="button"
        color="{buttonColor}"
        width="{buttonWidth}"
        position="{buttonPosition}"
      />
    </div>
  {/if}
</div>
