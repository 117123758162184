<style>span {
  text-decoration: underline;
  cursor: pointer;
  color: var(--primary);
}

button {
  border-width: 1px;
  border-radius: 0.375rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
  --bg-opacity: 1;
  background-color: #000;
  background-color: rgba(0, 0, 0, var(--bg-opacity));
  background-color: var(--primary);
}

button:hover {
  opacity: 0.95;
}</style>

<script>
import API from "@local/utils/api"
import TextInput from "./TextInput.svelte"
export let wfId = undefined
export let amount = undefined
export let coupon = {}
export let couponCode = ""
let internalCouponCode = ""
let open = false

async function apply() {
  try {
    const sanitizedCode = `${internalCouponCode}`.trim().toUpperCase()
    coupon = await API.get(`payments/${wfId}/coupon`, {
      searchParams: {
        role: "patient",
        couponCode: sanitizedCode,
        amount,
      },
    }).json()
    couponCode = sanitizedCode
  } catch (err) {
    coupon = {}
    couponCode = ""
    console.log(err)
    alert("This coupon code is either invalid or expired.")
  }
}
</script>

<div class="flex justify-end {$$props.class || ''}">
  {#if !coupon.couponCode}
    {#if !open}
      <span on:click="{() => (open = true)}">I have a coupon code</span>
    {:else}
      <div class="flex flex-row gap-2">
        <div class="w-40">
          <TextInput
            bind:value="{internalCouponCode}"
            placeholder="Coupon Code"
          />
        </div>
        <button type="button" on:click="{apply}">Apply</button>
      </div>
    {/if}
  {/if}
</div>
