<style>.item {
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.item.current {
  --bg-opacity: 1;
  background-color: #e2e8f0;
  background-color: rgba(226, 232, 240, var(--bg-opacity));
  border-radius: 0.375rem;
  --text-opacity: 1;
  color: #2d3748;
  color: rgba(45, 55, 72, var(--text-opacity));
}

.main :global(h1) {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.875rem;
  font-weight: 700;
}

.main :global(h2) {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  font-weight: 600;
}

.main :global(p) {
  margin-bottom: 1rem;
}

.main :global(a) {
  text-decoration: underline;
  --text-opacity: 1;
  color: #63b3ed;
  color: rgba(99, 179, 237, var(--text-opacity));
}

.main :global(ol) {
  list-style-type: decimal;
  padding-left: 1.5rem;
}

.main :global(ul) {
  list-style-type: disc;
  padding-left: 1.5rem;
}

.main :global(li:not(:last-child)) {
  margin-bottom: 0.5rem;
}</style>

<script>
import { link } from "svelte-routing"
export let current = "terms"
</script>

<div class="flex w-full max-w-screen-xl mx-auto py-8">
  <div class="flex flex-col text-gray-600 gap-2 flex-0 w-72 mr-8">
    <a
      use:link
      href="/terms/terms-of-service"
      class="item"
      class:current="{current === 'terms'}"
    >Terms of Service</a>
    <a
      use:link
      href="/terms/privacy-policy"
      class="item"
      class:current="{current === 'privacy'}"
    >Privacy Policy</a>
    <a
      use:link
      href="/terms/medical-terms-of-service"
      class="item"
      class:current="{current === 'medical'}"
    >Medical Terms</a>
  </div>
  <div class="main bg-white rounded-md flex-1 py-2 px-4">
    <slot />
  </div>
</div>
