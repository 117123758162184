<style>button {
  border-radius: 0;
  margin: 0;
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
  outline: 2px solid transparent;
  outline-offset: 2px;
  padding: 0.5rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  border-bottom-width: 3px;
  border-color: transparent;
}

.selected {
  outline: 2px solid transparent;
  outline-offset: 2px;
  border-color: var(--primary);
  color: var(--primary);
}</style>

<script>
import { getContext } from "svelte"
import { TABS } from "./Tabs.svelte"

const tab = {}
const { registerTab, selectTab, selectedTab } = getContext(TABS)

registerTab(tab)
</script>

<button
  class="tab type-paragraph"
  class:selected="{$selectedTab === tab}"
  on:click|preventDefault|stopPropagation="{() => selectTab(tab)}"
>
  <slot />
</button>
