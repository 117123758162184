<style>button {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  border-width: 1px;
  border-radius: 0.375rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
}

button.filled {
  background-color: var(--primary);
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
  border-color: transparent;
}

button.outlined {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  color: var(--primary);
  border-color: var(--primary);
}

button:hover {
  opacity: 0.95;
}

button:disabled,
button[disabled] {
  opacity: 0.5;
  cursor: wait;
}</style>

<script>
export let type = "submit"
export let filled = true
$: outlined = !filled
</script>

<button type="{type}" class:filled class:outlined on:click>
  <slot />
</button>
