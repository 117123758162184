<style>a {
  cursor: pointer;
  text-transform: uppercase;
  text-align: left;
  display: inline;
}

a:hover {
  text-decoration: underline;
}

img {
  height: 2.5rem;
  margin-left: 0.5rem;
}</style>

<script>
export let label = ""
export let url = ""
export let className = ""
export let icon = ""
export let newWindow = true
</script>

{#if newWindow}
  <a target="_blank" href="{url}" class="{className}">
    <div class="flex flex-start">
      {#if icon}<img src="/images/{icon}" alt="{icon}" />{/if}
      <div class="ml-2">{label}</div>
    </div>
  </a>
{:else}
  <a href="{url}" class="{className}">
    <div class="flex flex-start">
      {#if icon}<img src="/images/{icon}" alt="{icon}" />{/if}{label}
    </div>
  </a>
{/if}
