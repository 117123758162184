<style>.label {
  line-height: 1.25;
  letter-spacing: 0;
  font-size: 1.125rem;
  font-weight: 600;
  cursor: pointer;
}

.checkbox {
  flex: none;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.75rem;
}

.checkbox:checked {
  accent-color: var(--primary);
}</style>

<script>
export let name = ""
export let label = ""
export let className = ""
export let checked = false
export let value = undefined

$: checked, onChange()

function onChange() {
  value = checked
}
</script>

<div class="flex items-center w-full {className}">
  <input id="{name}" class="checkbox" type="checkbox" bind:checked />
  <label for="{name}" class="label">{label}</label>
</div>
