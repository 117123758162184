<style>.button {
  height: 3.75rem;
  user-select: none;
}

.input {
  display: none;
}

.label {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  font-weight: 400;
  cursor: pointer;
  border-radius: 0.25rem;
  font-size: 1.3125rem;
  box-shadow: 0 0 16px 4px rgba(0, 0, 0, 0.1);
}

.input:checked + .label {
  font-weight: 500;
  background-color: var(--primary-lighter);
  color: var(--primary);
}

.icon {
  flex: none;
  margin-left: 0.75rem;
}</style>

<script>
export let id = undefined
export let type = undefined
export let label = undefined
export let icon = undefined
export let value = undefined
export let group = undefined
export let checked = undefined
</script>

<div class="button">
  {#if type === 'checkbox'}
    <input
      type="checkbox"
      class="input"
      bind:group
      bind:checked
      id="{id}"
      value="{value}"
    />
  {:else}
    <input type="radio" class="input" bind:group id="{id}" value="{value}" />
  {/if}
  <label for="{id}" class="label">
    {#if icon}<i class="icon material-icons">{icon}</i>{/if}
    <div class="flex-1 text-center">{label}</div>
  </label>
</div>
