<style>.box-content {
  border-radius: 0.5rem;
  border-width: 2px;
  overflow: hidden;
  background-color: #f7f7f7;
  border-color: #eaeaea;
}

.box-content.hasScreenshot {
  border-width: 0;
}

.text {
  color: #757575;
}</style>

<script>
import { onMount } from "svelte"
import { doctorRole } from "@local/config/constants"
import API from "@local/utils/api"
import Button from "./Button.svelte"
import { workflowLang } from "@shared/store/workflowKey"
import {
  retryText,
  askPatientToShowTheIDText,
  takeScreenshotText,
} from "@shared/utils/translations"

export let className = ""
export let name = undefined
export let value = undefined
export let userId = undefined
export let wfId = undefined
export let idDocumentImagePath = undefined

let canvas
let hasScreenshot = false

async function takeScreenshot() {
  hasScreenshot = true

  let video = document.querySelector("#patientVideo > video")

  canvas.width = video.videoWidth
  canvas.height = video.videoHeight
  canvas
    .getContext("2d")
    .drawImage(video, 0, 0, video.videoWidth, video.videoHeight)

  try {
    const fieldContentType = "image/jpeg"
    const { uploadUrl, url } = await API.post(`uploads/${wfId}`, {
      searchParams: { role: doctorRole },
      json: { field: name, fieldContentType, userId },
    }).json()

    value = url

    const blob = await new Promise((x) => canvas.toBlob(x, fieldContentType))
    // console.log({ blob })

    await fetch(uploadUrl, {
      method: "PUT",
      body: blob,
      headers: { "Content-Type": fieldContentType },
    })
  } catch (e) {
    console.log(e)
  }
}

function clearScreenshot() {
  hasScreenshot = false

  canvas.width = 0
  canvas.height = 0

  const context = canvas.getContext("2d")
  context.clearRect(0, 0, canvas.width, canvas.height)
}

onMount(async () => {
  if (idDocumentImagePath) {
    let image = new Image()
    image.onload = function () {
      canvas.width = image.width
      canvas.height = image.height
      canvas.getContext("2d").drawImage(image, 0, 0)
    }
    image.src = idDocumentImagePath

    hasScreenshot = true
  }
})
</script>

<div class="{className}">
  <div class="box-content" class:hasScreenshot>
    {#if !hasScreenshot}
      <div class="p-12">
        <p class="mb-6 text-center type-paragraph text">
          {askPatientToShowTheIDText[$workflowLang]}
        </p>
        <Button
          type="button"
          on:click="{takeScreenshot}"
          color="primary"
          filled="{false}"
          label="{takeScreenshotText[$workflowLang]}"
        />
      </div>
    {/if}

    <div class="w-full">
      <canvas class="w-full" bind:this="{canvas}" height="0"></canvas>
    </div>
  </div>

  {#if hasScreenshot}
    <Button
      type="button"
      on:click="{clearScreenshot}"
      className="mt-4"
      filled="{false}"
      label="{retryText[$workflowLang]}"
    />
  {/if}
</div>
