<style>.textarea-wrapper {
  width: 100%;
  border-width: 0;
  border-bottom-width: 4px;
  padding-top: 1.5rem;
  border-radius: 0.25rem;
  position: relative;
  background-color: #f3f3f3;
  border-color: #757575;
}

.textarea-wrapper.focused {
  border-color: var(--primary);
}

.textarea-wrapper.has-errors {
  border-color: #c4272a;
}

.textarea {
  display: block;
  width: 100%;
  background-color: transparent;
  border-width: 0;
  outline: 2px solid transparent;
  outline-offset: 2px;
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
  font-size: 1.125rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.25rem;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transition-duration: 200ms;
  appearance: none;
  line-height: 1.5rem;
  letter-spacing: -0.43px;
}

.textarea.growable {
  padding-right: 2.5rem;
}

.textarea.unresizable {
  resize: none;
}

.textarea:disabled {
  cursor: not-allowed;
  color: #757575;
  background-color: #dcdcdc;
}

.label {
  width: 100%;
  font-size: 1.125rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transition-duration: 200ms;
  position: absolute;
  top: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #9b9b9b;
  background-color: #f3f3f3;
  line-height: 1.25rem;
}

.label.disabled {
  cursor: not-allowed;
  color: #757575;
  background-color: #dcdcdc;
}

.label.expanded {
  padding-bottom: 0.25rem;
  padding-top: 0.5rem;
  font-size: 0.75rem;
  line-height: 1;
}

.label.focused {
  pointer-events: none;
  color: var(--primary);
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 1.125rem;
  cursor: pointer;
  font-size: 24px;
}

.icon:hover {
  color: #757575;
}</style>

<script>
export let name = ""
export let label = ""
export let value = undefined
export let hasErrors = false

export let growable = false
export let resizable = false
export let rows = 3
export let disabled = false

let focused = false
let grown = false

$: expanded = value || focused
$: error = !focused && hasErrors
$: rows = grown ? rows + 5 : rows
</script>

<div
  class="textarea-wrapper"
  class:focused="{focused}"
  class:has-errors="{hasErrors}"
>
  {#if label}
    <label
      for="{name}"
      class="label"
      class:focused="{focused}"
      class:expanded="{expanded}"
      class:disabled="{disabled}"
    >
      {label}
    </label>
  {/if}
  <textarea
    class="textarea"
    class:growable="{growable}"
    class:unresizable="{!resizable}"
    name="{name}"
    id="{name}"
    on:focus="{() => (focused = true)}"
    on:blur="{() => (focused = false)}"
    bind:value="{value}"
    rows="{rows}"
    disabled="{disabled}"></textarea>
  {#if growable}
    <i class="icon material-icons" on:click="{() => (grown = !grown)}">
      open_in_full
    </i>
  {/if}
</div>
