<style>.avatar {
  font-size: 2.25rem;
}

.menu-wrapper {
  position: absolute;
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  right: 0;
  left: 0;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  z-index: 50;
  top: 100%;
}

@media (min-width: 768px) {
  .menu-wrapper {
    left: auto;
  }
}

.menu-item {
  white-space: nowrap;
  font-size: 1rem;
}

.menu-item > a,
.menu-item > span {
  display: block;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  line-height: 2;
  cursor: pointer;
}

.menu-item > a:hover,
.menu-item > span:hover {
  background-color: var(--primary-lighter);
}</style>

<script>
import { link } from "svelte-routing"

import { currentUser, deauth } from "../../store/auth"

import { logoutText, yourWorkstationText } from "@shared/utils/translations"
import { workflowLang } from "@shared/store/workflowKey"

let opened = false
let showWorkstation = $currentUser.roles.some((role) =>
  ["doctor", "educator"].includes(role)
)

function clickOutside(node, { enabled: initialEnabled, cb }) {
  const handleOutsideClick = ({ target }) => {
    if (!node.contains(target)) {
      cb()
    }
  }

  function update({ enabled }) {
    if (enabled) {
      window.addEventListener("click", handleOutsideClick)
    } else {
      window.removeEventListener("click", handleOutsideClick)
    }
  }

  update({ enabled: initialEnabled })

  return {
    update,
    destroy() {
      window.removeEventListener("click", handleOutsideClick)
    },
  }
}

function close() {
  opened = false
}
</script>

<div
  use:clickOutside="{{ enabled: opened, cb: close }}"
  class="flex items-center justify-center h-full md:relative"
>
  <div
    class="flex flex-row items-center justify-center w-12 h-12 text-white bg-gray-500 rounded-full shadow-inner cursor-pointer hover:opacity-75"
    on:click="{() => (opened = !opened)}"
  >
    <i class="avatar material-icons">person</i>
  </div>
  {#if opened}
    <ul class="menu-wrapper" on:click="{close}">
      {#if showWorkstation}
        <li class="menu-item">
          <a
            href="/doctor-workstation"
            use:link
          >{yourWorkstationText[$workflowLang]}</a>
        </li>
      {/if}
      <li class="menu-item">
        <span on:click="{deauth}">{logoutText[$workflowLang]}</span>
      </li>
    </ul>
  {/if}
</div>
