<style>h1 {
  line-height: 1.25;
  letter-spacing: -0.025em;
  text-align: center;
  white-space: pre-wrap;
  font-size: 2.25rem;
  font-weight: 600;
  margin-bottom: 3rem;
}

ul {
  list-style-type: disc;
  padding-left: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

ol {
  list-style-type: decimal;
  padding-left: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}</style>

<script>
import { onMount } from "svelte"
import Section from "./payment/Section.svelte"
import Button from "./payment/Button.svelte"
import { workflowLang } from "@shared/store/workflowKey"
import {
  continueText,
  lookUpAPopUpText,
  clickToContinueText,
  beSureToClickOnAllowText,
  welcomeBackForYourVideoAppoitmentText,
  thereAreAfewPossibleReasonsText,
  yourAccidentallyDeniedAccessText,
  yourCameraAndMicrophoneAreNotWorkingText,
  ifSoYouCanClickTryAgainBelowText,
  ifYouAreStillHavingTroubleWeSuggestClickingGetHelpText,
  somethingWentWrongWeCantAccessYourCameraAndMicrophoneText,
  itCouldBeHelpfulToShareTheTecnicalText,
  noteText,
  getHelpText,
  tryAgainText,
  joinText,
  greatEverythingIsAllSetText,
  youCanNowProceedText,
} from "@shared/utils/translations"

let showDebugInfo = false
let debugInfo = ""

const DEVICE_CAMERA = "videoinput"
const DEVICE_MIC = "audioinput"

const STATUS_UNKNOWN = "unknown"
const STATUS_ALLOWED = "allowed"
const STATUS_DENIED = "denied"
const STATUS_CAMERA_NOT_FOUND = "cameranotfound"
const STATUS_MIC_NOT_FOUND = "micnotfound"
const STATUS_READY = "ready"

let status = STATUS_UNKNOWN

async function devicesConceiled() {
  const devices = await navigator.mediaDevices.enumerateDevices()
  return devices.some((device) => !(device.deviceId && device.label))
}

async function setupDevices() {
  try {
    const trackTypes = { audio: true, video: true }
    const mediaStream = await navigator.mediaDevices.getUserMedia(trackTypes)
    mediaStream.getTracks().forEach((track) => track.stop())
  } catch (err) {
    debugInfo = err
    status = STATUS_DENIED
    return
  }

  status = STATUS_READY
}

async function getHelp() {
  showDebugInfo = true
  window.Intercom && window.Intercom("show")
}

onMount(async function () {
  if (!(await devicesConceiled())) {
    status = STATUS_ALLOWED

    await setupDevices()
  }
})
</script>

{#if status === STATUS_UNKNOWN}
  <h1>{welcomeBackForYourVideoAppoitmentText[$workflowLang]}</h1>
  <Section class="mb-12" title="">
    <ol>
      <li>{clickToContinueText[$workflowLang]}</li>
      <li>{lookUpAPopUpText[$workflowLang]}</li>
      <li>{beSureToClickOnAllowText[$workflowLang]}</li>
    </ol>
  </Section>
  <div class="flex flex-row justify-end">
    <Button on:click="{setupDevices}" type="button">
      {continueText[$workflowLang]}
      <i class="icon material-icons"> east </i>
    </Button>
  </div>
{/if}

{#if status === STATUS_DENIED}
  <h1>
    {somethingWentWrongWeCantAccessYourCameraAndMicrophoneText[$workflowLang]}
  </h1>
  <Section class="mb-12" title="">
    {thereAreAfewPossibleReasonsText[$workflowLang]}
    <ul>
      <li>
        {yourAccidentallyDeniedAccessText[$workflowLang]}
        <span
          class="italic"
        >{ifSoYouCanClickTryAgainBelowText[$workflowLang]}</span>
      </li>
      <li>{yourCameraAndMicrophoneAreNotWorkingText[$workflowLang]}</li>
    </ul>
    {ifYouAreStillHavingTroubleWeSuggestClickingGetHelpText[$workflowLang]}
  </Section>

  {#if showDebugInfo && debugInfo}
    <Section class="mb-12" title="">
      <span
        class="font-bold"
      >{noteText[$workflowLang]}</span>:{itCouldBeHelpfulToShareTheTecnicalText[$workflowLang]}
      <br /><br />
      <span class="text-red-500">{debugInfo}</span>
    </Section>
  {/if}

  <div class="flex flex-row justify-between">
    <Button on:click="{getHelp}" filled="{false}" type="button">
      {getHelpText[$workflowLang]}
      <i class="icon material-icons"> contact_support </i>
    </Button>
    <Button on:click="{setupDevices}" type="button">
      {tryAgainText[$workflowLang]}
      <i class="icon material-icons"> east </i>
    </Button>
  </div>
{/if}

{#if status === STATUS_READY}
  <h1>{greatEverythingIsAllSetText[$workflowLang]}</h1>
  <Section class="mb-12" title="">
    {youCanNowProceedText[$workflowLang]}
  </Section>
  <div class="flex flex-row justify-end">
    <Button type="submit">{joinText[$workflowLang]}</Button>
  </div>
{/if}
