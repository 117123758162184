<style>h1 {
  line-height: 1.25;
  letter-spacing: -0.025em;
  text-align: center;
  white-space: pre-wrap;
  font-size: 2.25rem;
  font-weight: 600;
}

.font-size-sm {
  font-size: 0.875rem;
}

.font-size-normal {
  font-size: 1rem;
}

.font-size-lg {
  font-size: 1.125rem;
}

.font-size-xl {
  font-size: 1.25rem;
}

.font-size-2xl {
  font-size: 1.5rem;
}

.font-size-3xl {
  font-size: 1.875rem;
}

.font-weight-normal {
  font-weight: 400;
}

.font-weight-semibold {
  font-weight: 600;
}

.font-weight-bold {
  font-weight: 700;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.text-align-right {
  text-align: right;
}

@media (min-width: 768px) {
  h1 {
    margin-left: -3rem;
    margin-right: -3rem;
  }
}

@media (min-width: 1024px) {
  h1 {
    margin-left: -6rem;
    margin-right: -6rem;
  }
}</style>

<script>
export let className = ""
export let content = ""
</script>

<h1 class="{className}">
  {@html content}
</h1>
