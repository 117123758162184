<style>.back-link {
  display: flex;
  align-items: center;
  cursor: pointer;
  align-self: flex-start;
  font-weight: 600;
  color: var(--primary);
}

.back-link:hover .label {
  text-decoration: underline;
}

.icon {
  font-size: 24px;
}</style>

<script>
export let actions = undefined
export let label = undefined
export let amount = 1
export let className = ""
</script>

<div
  class="back-link type-paragraph {className}"
  on:click="{() => actions.back(amount)}"
>
  <i class="icon material-icons">keyboard_arrow_left</i>
  <span class="label">{label}</span>
</div>
