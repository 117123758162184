<style>.edit-link {
  display: inline-block;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
  font-weight: 600;
  color: var(--primary);
  font-size: 17px;
  letter-spacing: -0.41px;
  line-height: 24px;
}

.edit-link:hover {
  text-decoration: underline;
}</style>

<script>
export let actions = {}
export let amount = 1
export let className = ""
</script>

<div class="edit-link {className}" on:click="{() => actions.back(amount)}">
  Edit
</div>
