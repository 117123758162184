<style>.input-wrapper {
  width: 100%;
  border-width: 0;
  border-bottom-width: 4px;
  border-radius: 0.25rem;
  position: relative;
  background-color: #f3f3f3;
  border-color: #757575;
}

.input-wrapper.focused {
  border-color: var(--primary);
}

.input-wrapper.has-errors {
  border-color: #c4272a;
}

.input {
  width: 100%;
  background-color: transparent;
  border-width: 0;
  outline: 2px solid transparent;
  outline-offset: 2px;
  padding-top: 1.5rem;
  padding-bottom: 0.25rem;
  --text-opacity: 1;
  color: #000;
  color: rgba(0, 0, 0, var(--text-opacity));
  font-size: 1.125rem;
  padding-left: 1rem;
  padding-right: 1rem;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transition-duration: 200ms;
  appearance: none;
  line-height: 1.5rem;
}

.label {
  width: 100%;
  font-size: 1.125rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transition-duration: 200ms;
  position: absolute;
  top: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #9b9b9b;
  line-height: 1.25rem;
}

.label.expanded {
  padding-bottom: 0.25rem;
  padding-top: 0.5rem;
  font-size: 0.75rem;
  line-height: 1;
}

.label.focused {
  pointer-events: none;
  color: var(--primary);
}

.input.hasLeftIcon,
.label.hasLeftIcon {
  padding-left: 3rem;
}

.icon,
.left-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  position: absolute;
  top: 0;
  width: 3.25rem;
  height: 100%;
}

.icon {
  right: 0;
}

.left-icon {
  left: 0;
}

.icon.clickable {
  cursor: pointer;
}

.icon.clickable:hover {
  color: #757575;
}</style>

<script>
import { createEventDispatcher } from "svelte"

export let name = undefined
export let label = undefined
export let value = undefined
export let hasErrors = undefined
export let inputEl = undefined
export let icon = undefined
export let leftIcon = undefined
export let iconClickable = undefined
export let className = ""
let focused = false
let _errors = []

$: expanded = value || focused
$: hasIcon = !!icon
$: hasLeftIcon = !!leftIcon
$: errors = _errors || []
$: hasErrors = errors.length > 0

const dispatch = createEventDispatcher()

function iconClicked() {
  if (iconClickable) dispatch("iconClick")
}

function handleInput(evt) {
  value = evt.target.value
  dispatch("valueChange", { value })
}
</script>

<div
  class="input-wrapper {className}"
  class:focused="{focused}"
  class:has-errors="{hasErrors}"
>
  {#if hasLeftIcon}<i class="left-icon material-icons">{leftIcon}</i>{/if}
  {#if label}
    <label
      for="{name}"
      class="label"
      class:focused="{focused}"
      class:expanded="{expanded}"
      class:hasLeftIcon="{hasLeftIcon}"
    >
      {label}
    </label>
  {/if}
  <input
    class="input"
    name="{name}"
    id="{name}"
    type="number"
    on:focus="{() => (focused = true)}"
    on:blur="{() => (focused = false)}"
    on:accept
    on:complete
    on:input="{handleInput}"
    bind:this="{inputEl}"
    class:hasLeftIcon="{hasLeftIcon}"
    value="{value}"
    step="any"
    min="0"
  />
  {#if hasIcon}
    <i
      class="icon material-icons"
      class:clickable="{iconClickable}"
      on:click="{iconClicked}"
    >
      {icon}
    </i>
  {/if}
  {#if hasErrors}
    <div class="mt-2 text-red-500">{errors[0]}</div>
  {/if}
</div>
