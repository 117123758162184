<style>.content {
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  width: 100%;
  min-height: calc(100vh - var(--top-bar-offset));
}</style>

<div class="content">
  <slot />
</div>
