<style>.wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  align-items: center;
  justify-content: center;
  width: 100%;
  --bg-opacity: 1;
  background-color: #000;
  background-color: rgba(0, 0, 0, var(--bg-opacity));
  min-height: calc(100vh - var(--top-bar-offset));
}

.video-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  max-height: calc(100vh - var(--top-bar-offset) - 2rem);
}

.video-wrapper.fullscreen {
  max-height: 100vh;
}

.video,
.video :global(video) {
  width: 100%;
  height: 100%;
  max-height: calc(100vh - var(--top-bar-offset) - 2rem);
}

.video.fullscreen,
.video.fullscreen :global(video) {
  max-height: 100vh;
}

.other-videos {
  position: absolute;
  width: 8rem;
  right: 0;
  top: 0;
  padding: 1rem;
}

.other-videos :global(video) {
  --border-opacity: 1;
  border-color: #000;
  border-color: rgba(0, 0, 0, var(--border-opacity));
  border-width: 2px;
  --bg-opacity: 1;
  background-color: #4a5568;
  background-color: rgba(74, 85, 104, var(--bg-opacity));
}

.buttons {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  --text-opacity: 1;
  color: #fff;
  color: rgba(255, 255, 255, var(--text-opacity));
}

.buttons {
  position: absolute;
  bottom: 0;
}

.button {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9999px;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  cursor: pointer;
  background-color: #757575;
}

.button:hover {
  opacity: 0.75;
}

/* .button.end-call {
  background-color: #fd5e53;
} */

@media (min-width: 768px) {
  .other-videos {
    width: 16rem;
  }

  .button:last-child {
    position: absolute;
    right: 0;
    margin-right: 6rem;
  }
}</style>

<script>
import isMobile from "ismobilejs"
import { onMount, onDestroy } from "svelte"
import Video from "twilio-video"

import { ready } from "../store/devices"

let containerEl
let fullscreen = false

export let accessToken = undefined
export let userId
export let actions = undefined

let room
let myVideoTrack
let myAudioTrack
let preferredTrackName
let videoTracks = []
let audioTracks = []

let myVideoEnabled = true
let myAudioEnabled = true

let videoContainerEl
let audiosContainerEl
let otherVideosContainerEl

async function visibilityChange() {
  if (document.visibilityState === "hidden") {
    myVideoEnabled = false
    myVideoTrack.stop()
    room.localParticipant.unpublishTrack(myVideoTrack)
  } else {
    myVideoTrack = await Video.createLocalVideoTrack({ disabled: true })
    refreshVideo()
    await room.localParticipant.publishTrack(myVideoTrack)
  }
}

function refreshAudio() {
  console.log("refreshAudio", audioTracks)

  for (let track of audioTracks) {
    console.log("removing audio track", track)
    const actualTrack = track.track || track
    if (actualTrack.detach) {
      console.log("detaching audio track", audiosContainerEl.children)
      actualTrack.detach()
    }
    for (let el of audiosContainerEl.children) {
      console.log("removing audio el", el)
      el.remove()
    }
  }

  let peers = Array.from(room.participants.values())

  audioTracks = peers.reduce((tracks, peer) => {
    const peerTracks = Array.from(peer.audioTracks.values())
    return [...peerTracks, ...tracks]
  }, [])

  if (audioTracks.length > 0) {
    for (let track of audioTracks) {
      const actualTrack = track.track || track
      if (actualTrack.attach)
        audiosContainerEl.appendChild(actualTrack.attach())
    }
  }
}

function refreshVideo() {
  console.log("refreshVideo", videoTracks)

  for (let track of videoTracks) {
    console.log("removing video track", track)
    const actualTrack = track.track || track
    if (actualTrack.detach) {
      console.log("detaching video track", track)
      actualTrack.detach()
    }
    for (let el of videoContainerEl.children) {
      console.log("removing main video el", el)
      el.remove()
    }
    for (let el of otherVideosContainerEl.children) {
      console.log("removing other video el", el)
      el.remove()
    }
  }

  let peers = Array.from(room.participants.values())

  videoTracks = peers.reduce(
    (tracks, peer) => {
      const peerTracks = Array.from(peer.videoTracks.values())
      return [...peerTracks, ...tracks]
    },
    [...(myVideoEnabled ? [myVideoTrack] : [])]
  )

  let preferredTrackIdx = videoTracks.findIndex(
    (track) => (track.trackName || track.name) === preferredTrackName
  )

  // prioritizes screen share
  if (preferredTrackIdx === -1) {
    preferredTrackIdx = videoTracks.findIndex(
      (track) =>
        !!track.track && (track.trackName || track.name) === "screenShare"
    )
  }

  // default
  if (preferredTrackIdx === -1) {
    preferredTrackIdx = videoTracks.length > 0 ? 0 : -1
  }

  if (videoTracks.length > 0) {
    for (let i = 0; i < videoTracks.length; i++) {
      const track = videoTracks[i]
      const actualTrack = track.track || track
      const actualTrackName = track.trackName || track.name

      if (!track.isTrackEnabled && !track.isEnabled) continue

      if (actualTrack.attach) {
        const video = actualTrack.attach()

        if (actualTrackName !== "screenShare") {
          video.style.transform = "scale(-1, 1)"
        }

        if (preferredTrackIdx === i) {
          console.log("attaching main video", actualTrack)
          videoContainerEl.appendChild(video)
        } else {
          console.log("attaching side video", actualTrack)
          const newEl = video
          const evtHandler = (trackName) => () => {
            console.log(`changed preferredTrackName to ${trackName}`)
            preferredTrackName = trackName
            refreshVideo()
          }
          newEl.addEventListener(
            "click",
            evtHandler(track.trackName || track.name)
          )
          otherVideosContainerEl.appendChild(newEl)
        }
      }
    }
  }
}

async function disconnect() {
  if (room) {
    myVideoTrack.stop()
    myAudioTrack.stop()
    room.removeAllListeners()
    room.disconnect()
    room = null
  }
}

async function onReady(ready) {
  if (ready) {
    let myTracks = await Video.createLocalTracks()

    myVideoTrack = myTracks.find(({ kind }) => kind === "video")
    myAudioTrack = myTracks.find(({ kind }) => kind === "audio")

    if (isMobile(navigator).any) {
      document.addEventListener("visibilitychange", visibilityChange)
    }

    room = await Video.connect(accessToken, {
      name: userId,
      tracks: myTracks,
    })

    room.on("participantConnected", function (participant) {})

    room.on("participantDisconnected", function (participant) {
      console.log("participantDisconnected")
      refreshVideo()
      refreshAudio()
      participant.removeAllListeners()
    })

    function handleTrackChange(event) {
      return function (track) {
        console.log(event)
        if (track.kind !== "video") {
          refreshAudio()
        } else {
          refreshVideo()
        }
      }
    }

    room.on("trackSubscribed", handleTrackChange("trackSubscribed"))
    room.on("trackUnsubscribed", handleTrackChange("trackUnsubscribed"))
    room.on("trackDisabled", handleTrackChange("trackDisabled"))
    room.on("trackEnabled", handleTrackChange("trackEnabled"))

    refreshVideo()
  }
}

$: onReady($ready)

function fullscreenChange() {
  if (
    (document.fullscreenElement && document.fullscreenElement !== null) ||
    (document.webkitFullscreenElement &&
      document.webkitFullscreenElement !== null) ||
    (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
    (document.msFullscreenElement && document.msFullscreenElement !== null)
  ) {
    fullscreen = true
    return
  }
  fullscreen = false
}

onMount(function () {
  document.addEventListener("fullscreenchange", fullscreenChange)
  document.addEventListener("mozfullscreenchange", fullscreenChange)
  document.addEventListener("MSFullscreenChange", fullscreenChange)
  document.addEventListener("webkitfullscreenchange", fullscreenChange)
})

onDestroy(async function () {
  document.removeEventListener("fullscreenchange", fullscreenChange)
  document.removeEventListener("mozfullscreenchange", fullscreenChange)
  document.removeEventListener("MSFullscreenChange", fullscreenChange)
  document.removeEventListener("webkitfullscreenchange", fullscreenChange)
  document.removeEventListener("visibilitychange", visibilityChange)
  await disconnect()
})

function toggleFullscreen() {
  if (fullscreen) {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen()
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen()
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen()
    }
    return
  }

  if (containerEl.requestFullscreen) {
    containerEl.requestFullscreen()
  } else if (containerEl.webkitRequestFullscreen) {
    containerEl.webkitRequestFullscreen()
  } else if (docElm.mozRequestFullScreen) {
    containerEl.mozRequestFullScreen()
  } else if (containerEl.msRequestFullscreen) {
    containerEl.msRequestFullscreen()
  }
}

function toggleVideo() {
  if (myVideoEnabled) {
    myVideoTrack.disable()
    myVideoEnabled = false
  } else {
    myVideoTrack.enable()
    myVideoEnabled = true
  }
  refreshVideo()
}

function toggleAudio() {
  if (myAudioEnabled) {
    myAudioTrack.disable()
    myAudioEnabled = false
  } else {
    myAudioTrack.enable()
    myAudioEnabled = true
  }
}

async function endCall() {
  if (fullscreen) {
    document.exitFullscreen()
  }
  await actions.back()
}
</script>

<svelte:window on:beforeunload="{disconnect}" />
<div bind:this="{audiosContainerEl}"></div>
<div class="wrapper" bind:this="{containerEl}">
  <div class="video-wrapper" class:fullscreen>
    {#if $ready}
      <div class="video" class:fullscreen bind:this="{videoContainerEl}"></div>
    {/if}
    <div class="other-videos" bind:this="{otherVideosContainerEl}"></div>
    <div class="buttons">
      <div class="button" on:click="{toggleAudio}">
        <i class="material-icons">{myAudioEnabled ? 'mic' : 'mic_off'}</i>
      </div>
      <div class="button" on:click="{toggleVideo}">
        <i class="material-icons">
          {myVideoEnabled ? 'videocam' : 'videocam_off'}
        </i>
      </div>
      <div class="button" on:click="{toggleFullscreen}">
        <i class="material-icons">
          {#if fullscreen}fullscreen_exit{:else}fullscreen{/if}
        </i>
      </div>
    </div>
  </div>
</div>
