<style>h2 {
  line-height: 1.25;
  letter-spacing: 0;
  font-size: 1.25rem;
  font-weight: 600;
  text-align: center;
}

.wrapper {
  width: 100%;
}

.calendar {
  width: 100%;
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  border-radius: 0.25rem;
  padding: 1rem;
  margin-bottom: 1rem;
  user-select: none;
  font-size: 1.25rem;
  box-shadow: 0 0 16px 4px rgba(0, 0, 0, 0.1);
}

.calendar-label {
  line-height: 1.25;
  letter-spacing: 0;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
}

.error {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  line-height: 1;
  color: #c4272a;
}

.chevron {
  font-size: 1.875rem;
  cursor: pointer;
  color: var(--primary);
}

.chevron:hover {
  opacity: 0.75;
}

.weekday {
  text-transform: uppercase;
  color: #757575;
}

.day {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 600;
}

.weekday,
.day {
  height: 2.5rem;
  font-size: 0.875rem;
}

.day.selected {
  border-radius: 9999px;
  background-color: var(--primary-lighter);
  color: var(--primary);
}

.day.past {
  pointer-events: none;
  cursor: default;
  font-weight: 400;
  color: #757575;
}

.hours {
  margin-top: 2.5rem;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 1rem;
  gap: 1rem;
}

@media (min-width: 1024px) {
  .day,
  .weekday {
    height: 3.25rem;
    font-size: 1rem;
  }

  .calendar {
    padding: 1.5rem;
  }

  .calendar-label {
    font-size: 1.875rem;
  }
}</style>

<script>
import { onMount } from "svelte"
import {
  format,
  isSameDay,
  startOfDay,
  eachDayOfInterval,
  isSameMonth,
  startOfMonth,
  endOfMonth,
  addMonths,
  differenceInCalendarMonths,
} from "date-fns"
import { fr, enUS as en } from "date-fns/locale"

import SubHeading from "./SubHeading.svelte"
import Button from "./Button.svelte"
import CheckButton from "./_CheckButton.svelte"

import { workflowLang } from "@shared/store/workflowKey"
import { weekdaysText } from "@shared/utils/translations"

const today = startOfDay(new Date())

export { _errors as errors }
export let value
export let className = ""
export let availableSchedules
export let noSlotsAvailableMessage = ""

let todayHours = []
let tomorrowHours = []

let showCalendar = true

const weekdays = [7, 1, 2, 3, 4, 5, 6]
let weekdaysLabels = weekdaysText[$workflowLang]
let localeLang = $workflowLang === "en" ? en : fr //for date-fns

let calendarDay = today
let calendarDelta = 0
let calendarMonth = null
let calendarLabel = null
let calendarOffset = null
let calendarDays = []
let calendarHours = []
let _errors = []

$: refreshCalendar(calendarDelta, $workflowLang)
$: refreshCalendarHours(calendarDay)

$: errors = _errors || []
$: hasErrors = errors.length > 0

function formatSlot(slot) {
  if (!slot) return
  return `${slot.startsAt}~${slot.endsAt}`
}

onMount(function () {
  if (availableSchedules.length > 0) {
    calendarDelta = differenceInCalendarMonths(
      new Date(availableSchedules[0].startsAt),
      today
    )

    localeLang = $workflowLang === "en" ? en : fr

    calendarMonth = startOfMonth(addMonths(today, calendarDelta))
    calendarLabel = format(calendarMonth, "MMMM yyyy", { locale: localeLang })

    const start = calendarMonth
    const end = endOfMonth(calendarMonth)
    calendarDays = eachDayOfInterval({ start, end })

    calendarDay = calendarDays.find((day) =>
      isSameDay(day, new Date(availableSchedules[0].startsAt))
    )

    const firstWeekDay = parseInt(format(calendarMonth, "i")) + 1
    calendarOffset = firstWeekDay === 8 ? 0 : firstWeekDay

    value = formatSlot(availableSchedules[0])
  }
})

function hasSlots(date) {
  return availableSchedules.some((slot) =>
    isSameDay(date, new Date(slot.startsAt))
  )
}

function refreshCalendar(calendarDelta) {
  calendarHours = []

  localeLang = $workflowLang === "en" ? en : fr
  weekdaysLabels = weekdaysText[$workflowLang]
  calendarMonth = startOfMonth(addMonths(today, calendarDelta))
  calendarLabel = format(calendarMonth, "MMMM yyyy", { locale: localeLang })

  const start = calendarMonth
  const end = endOfMonth(calendarMonth)
  calendarDays = eachDayOfInterval({ start, end })

  const firstWeekDay = parseInt(format(calendarMonth, "i")) + 1
  calendarOffset = firstWeekDay === 8 ? 0 : firstWeekDay

  if (availableSchedules.length > 0) {
    if (isSameMonth(start, today)) {
      calendarDay = calendarDays.find((day) =>
        isSameDay(day, new Date(availableSchedules[0].startsAt))
      )
    }
  }
}

function refreshCalendarHours(calendarDay) {
  calendarHours = availableSchedules.filter((slot) =>
    isSameDay(calendarDay, new Date(slot.startsAt))
  )
}

function openCalendar() {
  if (value) {
    for (let day of calendarDays) {
      if (isSameDay(day, new Date(value))) {
        calendarDay = day
        break
      }
    }
  }
  showCalendar = true
}

function changeCalendarDay(date) {
  calendarDay = date
  value = null
}
</script>

<div class="wrapper {className}">
  {#if showCalendar}
    <div class="calendar">
      <div class="flex items-center justify-between mb-6">
        <div class="calendar-label">{calendarLabel}</div>
        <div class="flex items-center">
          <i class="chevron material-icons" on:click="{() => calendarDelta--}">
            chevron_left
          </i>
          <i class="chevron material-icons" on:click="{() => calendarDelta++}">
            chevron_right
          </i>
        </div>
      </div>
      <div class="grid grid-cols-7 gap-1 text-center sm:gap-2">
        {#each weekdays as weekday}
          <div class="weekday">{weekdaysLabels[weekday - 1]}</div>
        {/each}
        {#each calendarDays as date, i}
          <div
            class="day"
            style="{i === 0 ? `grid-column-start: ${calendarOffset}` : ''}"
            class:selected="{isSameDay(date, calendarDay)}"
            class:past="{!hasSlots(date)}"
            on:click="{() => changeCalendarDay(date)}"
          >
            {i + 1}
          </div>
        {/each}
      </div>
    </div>
    {#if hasErrors}
      <div class="error">{errors[0]}</div>
    {/if}
    {#if availableSchedules && availableSchedules.length > 0}
      <div class="hours">
        {#each calendarHours as slot, i}
          <CheckButton
            type="radio"
            id="{`hours-${i}`}"
            value="{formatSlot(slot)}"
            bind:group="{value}"
            label="{format(new Date(slot.startsAt), 'h:mm aaa')}"
          />
        {/each}
      </div>
    {:else}
      <h2 class="mt-8">{noSlotsAvailableMessage}</h2>
    {/if}
  {:else}
    <SubHeading content="{'Today'}" className="mb-4" />
    <div class="mb-8 hours">
      {#each todayHours as hour, i}
        <CheckButton
          type="radio"
          id="{`today-hours-${i}`}"
          value="{hour}"
          bind:group="{value}"
          label="{format(new Date(hour), 'h:mm aaa')}"
        />
      {/each}
    </div>
    <SubHeading content="{'Tomorrow'}" className="mb-4" />
    <div class="hours">
      {#each tomorrowHours as hour, i}
        <CheckButton
          type="radio"
          id="{`tomorrow-hours-${i}`}"
          value="{hour}"
          bind:group="{value}"
          label="{format(new Date(hour), 'h:mm aaa')}"
        />
      {/each}
    </div>
  {/if}
  {#if !showCalendar}
    <div class="mt-16">
      <Button
        type="button"
        on:click="{openCalendar}"
        filled="{false}"
        color="primary"
        rightIcon="east"
        label="View all availability"
      />
    </div>
  {/if}
</div>
