<style>.wrapper {
  width: 100%;
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
}</style>

<script>
import Render from "./Render.svelte"
export let rows = []
export let allErrors = undefined
export let payload = undefined
export let actions = undefined
export let className = ""

$: cols = rows.reduce((max, row) => (max < row.length ? row.length : max), 0)

$: gridClass = `grid-cols-1 md:grid-cols-${cols}`

function colSpan(row, i) {
  if (row.length === cols || i !== row.length - 1) {
    return `md:col-span-1`
  } else {
    const extra = cols - row.length + 1
    return `md:col-span-${extra}`
  }
}
</script>

<div class="wrapper {gridClass} {className}">
  {#each rows as elements}
    {#each elements as element, i}
      <div class="{colSpan(elements, i)}">
        <Render
          actions="{actions}"
          element="{element}"
          allErrors="{allErrors}"
          bind:payload
        />
      </div>
    {/each}
  {/each}
</div>
