<style>h3 {
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}

section {
  width: 100%;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1.5rem;
  padding-bottom: 2rem;
}

section.gray {
  background-color: #fafafa; /* bg-gray-50 */
}

section.primary {
  border-color: var(--primary);
  border-style: solid;
  border-width: medium;
  background-color: var(--primary-lighter);
}</style>

<script>
export let title
export let primary = false
$: gray = !primary
</script>

<h3>{title}</h3>
<section class="{$$props.class || ''}" class:primary class:gray>
  <slot />
</section>
