<style>.wrapper {
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

.column {
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  min-height: calc(100vh - var(--top-bar-offset));
}

.column.visuals {
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  border-width: 1px;
  border-top-width: 0;
  --border-opacity: 1;
  border-color: #cbd5e0;
  border-color: rgba(203, 213, 224, var(--border-opacity));
}</style>

<script>
export let columnClasses =
  "w-full md:w-2/3 px-4 py-8 md:px-24 md:py-16 lg:px-48"
export let visuals = true
</script>

<div class="wrapper">
  <div class="column {columnClasses}" class:visuals>
    <slot />
  </div>
</div>
