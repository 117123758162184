<style>.wrapper {
  width: 100%;
  margin-bottom: 4rem;
  --bg-opacity: 1;
  background-color: #fff;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  border-radius: 1rem;
  box-shadow: 0 0 3.5rem 0 rgba(0, 0, 0, 0.1);
}

.date-wrapper {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 1.5rem;
  gap: 1.5rem;
  padding: 1.5rem;
}

.date-label {
  font-size: 12px;
  letter-spacing: -0.29px;
  line-height: 1.75;
}

.details {
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-top-width: 2px;
  --border-opacity: 1;
  border-color: #edf2f7;
  border-color: rgba(237, 242, 247, var(--border-opacity));
}

/* .details strong {
  @apply font-medium;
  color: var(--primary);
} */</style>

<script>
import format from "date-fns/format"
import { workflowLang } from "@shared/store/workflowKey"
import {
  timeText,
  dateText,
  checkYourEmailForInstructionsText,
} from "@shared/utils/translations"
import { fr, enUS as en } from "date-fns/locale"

export let schedule = undefined

$: localeLang = $workflowLang === "en" ? en : fr
$: time = format(new Date(schedule.startsAt), "h:mm aaa")
$: date = format(new Date(schedule.startsAt), "LLLL, d, yyyy", {
  locale: localeLang,
})
</script>

<div class="wrapper">
  <div class="date-wrapper">
    <div>
      <div class="date-label">{timeText[$workflowLang]}</div>
      <div class="type-paragraph">{time}</div>
    </div>
    <div>
      <div class="date-label">{dateText[$workflowLang]}</div>
      <div class="type-paragraph">{date}</div>
    </div>
  </div>
  <div class="details type-paragraph">
    {checkYourEmailForInstructionsText[$workflowLang]}
  </div>
</div>
